import {
  ValidateCarouselComponentProps,
  ValidateContentCardComponentProps,
  ValidateContentCardContainerComponentProps,
  ValidateCountdownBannerComponentProps,
  ValidateDestinationCarouselComponentProps,
  ValidateGlobalAlertBannerComponentProps,
  ValidateIconPanelComponentProps,
  ValidateTailfinHeroBannerContainerComponentProps,
  ValidateTailfinHeroBannerTwoContainerComponentProps,
  ValidateTextLockupComponentProps,
  ValidationOutcomes,
  ValidationResult,
  ValidateHeadingComponentProps,
  ValidateSpacerComponentProps,
  ValidatePromoBannerComponentProps,
  ValidateHotelsCarouselComponentProps,
  ValidateHotelsMapComponentProps,
  ValidateHeroBannerContainerComponentProps,
  ValidateSearchPanelComponentProps,
  ValidateAccordionProps,
  ValidateLinkBannerProps,
  ValidateBreadcrumbsProps,
  ValidateClimateChartComponentProps,
  ValidateTableCardProps,
  ValidateVideoComponentProps,
} from 'src/validation';
import ValidateTabsProps from 'src/validation/tabs-component-validator';
import ValidateArticlesListComponentProps from 'src/validation/articleslist-component-validator';

import { Rating } from '@virgin-atlantic/component-lib';
import ValidateRouteMapComponentProps from 'src/validation/route-map-component-validator';
import ValidateImageComponentProps from 'src/validation/image-component-validator';

function stripPath(s: string) {
  if (!s) return '';
  if (s.indexOf('/') === -1) return s;

  return s.split('/').pop();
}

const containsHTML = (str: string) => /<\/?[a-z][\s\S]*>/i.test(str);

const isObjectEmpty = (obj: any) =>
  obj &&
  Object.keys(obj).length === 0 &&
  Object.getPrototypeOf(obj) === Object.prototype;

const objectHasFields = (obj: any, fields: string[]): boolean =>
  fields.every((field) => obj.hasOwnProperty(field));

const dirtyKeys = [
  'cq:responsive',
  'cq:styleIds',
  'cq:lastRolledout',
  'cq:lastRolledoutBy',
  'jcr:created',
  'jcr:createdBy',
  'jcr:lastModified',
  'jcr:lastModifiedBy',
  'jcr:primaryType',
  'jcr:mixinTypes',
  // 'cq:panelTitle',
  // 'sling:resourceType',
];

// ob is the object to be cleaned, passed by reference
const cleanHeadlessData = (ob: any) => {
  if (typeof ob !== 'object') return;

  const iterate = (o: any) => {
    Object.keys(o).forEach((key: string) => {
      // Remove dirty keys
      if (dirtyKeys.includes(key)) delete o[key];
      // Convert string booleans to actual booleans
      if (o[key] === 'false') o[key] = false;
      if (o[key] === 'true') o[key] = true;
      if (typeof o[key] === 'object' && o[key] !== null) iterate(o[key]);
    });
  };
  iterate(ob);
};

const getColumnClassInfo = (s: string) => {
  if (!s) return { width: 12, offset: 0 };
  const classArray: string[] = s.split(' ');
  const widthClass = 'aem-GridColumn--default--';
  const widthValue = classArray.find((c) => c.startsWith(widthClass));
  const width: number = widthValue
    ? parseInt(widthValue.replace(widthClass, ''))
    : 12;

  const offsetClass = 'aem-GridColumn--offset--default--';
  const offsetValue = classArray.find((c) => c.startsWith(offsetClass));
  const offset: number = offsetValue
    ? parseInt(offsetValue.replace(offsetClass, ''))
    : 0;

  return { width, offset };
};

// Processes the props of each component and returns the processed props
const validateComponentProps = (props: any, componentType: string) => {
  if (!props)
    return {
      result: ValidationOutcomes.FAILURE,
      messages: ['No props were passed to the component'],
    };

  let vr: ValidationResult = {
    result: ValidationOutcomes.UNKNOWN,
    messages: [],
  };

  switch (componentType) {
    case 'global-alert-banner':
      vr = ValidateGlobalAlertBannerComponentProps(props);
      break;
    case 'icon-panel':
      vr = ValidateIconPanelComponentProps(props);
      break;
    case 'tailfin-hero-banner':
      vr = ValidateTailfinHeroBannerContainerComponentProps(props);
      break;
    case 'tailfin-hero-banner-two':
      vr = ValidateTailfinHeroBannerTwoContainerComponentProps(props);
      break;
    case 'herobanner':
      vr = ValidateHeroBannerContainerComponentProps(props);
      break;
    case 'promobanner':
      vr = ValidatePromoBannerComponentProps(props);
      break;
    case 'text-lockup-component':
      vr = ValidateTextLockupComponentProps(props);
      break;
    case 'content-card':
      vr = ValidateContentCardComponentProps(props);
      break;
    case 'content-card-container':
      vr = ValidateContentCardContainerComponentProps(props);
      break;
    case 'carousel':
      vr = ValidateCarouselComponentProps(props);
      break;
    case 'destination-carousel':
      vr = ValidateDestinationCarouselComponentProps(props);
      break;
    case 'hotels-carousel':
      vr = ValidateHotelsCarouselComponentProps(props);
      break;
    case 'hotels-map':
      vr = ValidateHotelsMapComponentProps(props);
      break;
    case 'image':
      vr = ValidateImageComponentProps(props);
      break;
    case 'countdown-timer':
      vr = ValidateCountdownBannerComponentProps(props);
      break;
    case 'heading-component':
      vr = ValidateHeadingComponentProps(props);
      break;
    case 'spacer-component':
      vr = ValidateSpacerComponentProps(props);
      break;
    case 'accordion':
      vr = ValidateAccordionProps(props);
      break;
    case 'tabs':
      vr = ValidateTabsProps(props);
      break;
    case 'search-panel':
      vr = ValidateSearchPanelComponentProps(props);
      break;
    case 'articleslist':
      vr = ValidateArticlesListComponentProps(props);
      break;
    case 'route-map':
      vr = ValidateRouteMapComponentProps(props);
      break;
    case 'link-banner':
      vr = ValidateLinkBannerProps(props);
      break;
    case 'breadcrumbs':
      vr = ValidateBreadcrumbsProps(props);
      break;
    case 'climate-chart':
      vr = ValidateClimateChartComponentProps(props);
      break;
    case 'table-card':
      vr = ValidateTableCardProps(props);
      break;
    case 'video-component':
      vr = ValidateVideoComponentProps(props);
      break;

    default:
      break;
  }

  return vr;
};

const hasKeyValuePair = (obj: any, key: string, value: any) => {
  if (typeof obj !== 'object' || obj === null) {
    return false;
  }

  if (obj[key] === value) {
    return true;
  }

  for (let prop in obj) {
    if (typeof obj[prop] === 'object') {
      if (hasKeyValuePair(obj[prop], key, value)) {
        return true;
      }
    }
  }

  return false;
};

const isOfTypeRating = (keyInput: string): keyInput is Rating => {
  return [
    '0',
    '0+',
    '1',
    '1+',
    '2',
    '2+',
    '3',
    '3+',
    '4',
    '4+',
    '5',
    '5+',
  ].includes(keyInput);
};

const replaceString = (str: string, search: string, replace: string) =>
  str.split(search).join(replace);

const hasPropertyAndEquals = (obj: any, key: string, value: any) =>
  obj.hasOwnProperty(key) && obj[key] === value;

export {
  stripPath,
  containsHTML,
  isObjectEmpty,
  objectHasFields,
  getColumnClassInfo,
  validateComponentProps,
  cleanHeadlessData,
  hasKeyValuePair,
  isOfTypeRating,
  replaceString,
  hasPropertyAndEquals,
};
