import getIcon from 'src/components/icons';
import { getAEMBasepath } from './basepaths';
import {
  BRANDS,
  currentBrand,
  currentEnvironment,
  ENVIRONMENTS,
  isPreviewMode,
  isTravelPlusHomepage,
} from './ssr-helper';

//TODO: Add more static routes as needed
const STATIC_ROUTES = ['destinations', 'holiday-types'];

const absoluteUrlLookup: any = {
  helpCentre1: 'help',
  helpCentre: 'flywith',
  'vaa-home': 'vaahome',
  vhols: 'vholshome',
  corporate: 'corporate',
};

const HTTP_PREFIX = 'http';
const PREVIEW_MODE_PATH = '/viewpage?brandPath=';
const SPECIAL_PATHS = STATIC_ROUTES;
const EN_PATH = '/en';

export function convertToAbsoluteImageUrl(imageUrl: string) {
  if (imageUrl.startsWith('http')) return imageUrl;

  if (!imageUrl.startsWith('/')) imageUrl = '/' + imageUrl;
  const imageBasePath = isPreviewMode
    ? getAEMBasepath().previewAsset
    : getAEMBasepath().main;

  const absoluteImageUrl = imageBasePath + imageUrl;

  return absoluteImageUrl;
}

function handleURLForStageAndProd({ lookupKey }: { lookupKey: string }) {
  if (lookupKey.includes('helpCentre')) {
    const envPrefix =
      currentEnvironment.toLowerCase() === ENVIRONMENTS.UAT ? 'stg-' : '';
    return `https://${envPrefix}${absoluteUrlLookup[lookupKey]}.virginatlantic.com`;
  }
  return getAEMBasepath().main;
}

function isSpecialPath(path: string): boolean {
  const segments = path.split('/');
  return (
    (segments[5] && SPECIAL_PATHS.includes(segments[6])) ||
    segments[4].toLowerCase() === EN_PATH.slice(1)
  );
}

export function convertToRelativeLinkUrl(linkProperty: string): string {
  if (!linkProperty.startsWith('/')) linkProperty = '/' + linkProperty;
  if (linkProperty.startsWith(HTTP_PREFIX)) return linkProperty;

  if (isSpecialPath(linkProperty)) {
    return isPreviewMode
      ? `${PREVIEW_MODE_PATH}${linkProperty}.model.json`
      : '/' + linkProperty.split('/').slice(5).join('/');
  }

  return convertToAbsoluteLinkUrl(linkProperty);
}

export function convertToAbsoluteLinkUrl(
  linkProperty: string,
  suffix: string | null = null
) {
  if (linkProperty === undefined) {
    return '';
  }

  if (linkProperty.startsWith('http')) {
    return linkProperty;
  }

  if (!linkProperty.startsWith('/')) {
    linkProperty = '/' + linkProperty;
  }

  const brand = currentBrand === BRANDS.VAA ? 'vaa-home' : 'vhols';
  const isProd = currentEnvironment === ENVIRONMENTS.PROD;
  const basePath = `/content/${brand}/global/en`;
  let targetDomain;

  if (isPreviewMode) {
    if (linkProperty?.includes('/preview/viewpage?brandPath=')) {
      return linkProperty;
    }
    linkProperty = linkProperty?.startsWith('/content')
      ? `/preview/viewpage?brandPath=${linkProperty}.model.json`
      : `/preview/viewpage?brandPath=${basePath}${linkProperty}.model.json`;
  } else if (!isProd) {
    linkProperty = linkProperty?.replace(
      new RegExp(`^/content/${brand}/[^/]+/[^/]+`),
      ''
    );
  } else if (isProd && linkProperty?.startsWith('/holidays')) {
    targetDomain = getAEMBasepath().publish;
    return targetDomain + linkProperty;
  }

  const linkSubUrls = linkProperty?.split('/');
  if (linkSubUrls.length < 3) {
    return linkProperty;
  }

  const lookupKey = linkSubUrls[2];
  if (!absoluteUrlLookup[lookupKey]) {
    return linkProperty;
  }

  let targetUrl = linkSubUrls?.slice(3).join('/');
  try {
    if (currentEnvironment.includes('test')) {
      targetDomain = `https://${currentEnvironment.toLowerCase()}-${absoluteUrlLookup[
        lookupKey
      ].toLowerCase()}.webdev.vholsinternal.co.uk`;
    } else if (currentEnvironment === ENVIRONMENTS.UAT) {
      targetDomain = handleURLForStageAndProd({ lookupKey });
    } else if (isProd) {
      targetDomain = getAEMBasepath().publish;
      const urlSegments = targetUrl?.split('/');
      if (urlSegments.length > 2) {
        targetUrl = urlSegments?.slice(2).join('/');
      }
    } else {
      return linkProperty;
    }

    const absoluteLink = `${targetDomain}/${targetUrl}`;
    return suffix ? `${absoluteLink}.html` : absoluteLink;
  } catch (e) {
    throw new Error('Error in convertToAbsoluteLinkUrl function');
  }
}

export const checkAndAddProps = (
  sourceProps: any,
  targetProps: any,
  propNames: string[]
) =>
  propNames.forEach(
    (propName) =>
      sourceProps.hasOwnProperty(propName) &&
      (targetProps[propName] = sourceProps[propName])
  );

export function getStringBetween(
  inputString: string,
  startString: string,
  endString: string
) {
  const start = inputString.indexOf(startString) + startString.length;
  const end = inputString.indexOf(endString, start);
  let stringBetween = '';
  try {
    stringBetween = inputString.substring(start, end);
  } catch (e) {
    console.error('Error in getStringBetween function', e, inputString);
  }

  return stringBetween;
}

export function replaceStringBetween(
  inputString: string,
  startString: string,
  endString: string,
  replacementString: string
) {
  const start = inputString.indexOf(startString);
  const end = inputString.lastIndexOf(endString);

  let replacedString = '';
  try {
    const stringBetween = inputString.substring(start, end + endString.length);
    replacedString = inputString.replace(stringBetween, replacementString);
  } catch (e) {
    console.error('Error in replaceStringBetween function', e, inputString);
  }

  return replacedString;
}

export function getTimestamp() {
  const timeNow = new Date();
  return `${
    timeNow.getHours() < 10 ? `0${timeNow.getHours()}` : `${timeNow.getHours()}`
  }:\
${
  timeNow.getMinutes() < 10
    ? `0${timeNow.getMinutes()}`
    : `${timeNow.getMinutes()}`
}:\
${
  timeNow.getSeconds() < 10
    ? `0${timeNow.getSeconds()}`
    : `${timeNow.getSeconds()}`
}:\
${timeNow.getMilliseconds()}`;
}

export function debugLog(...args: any[]) {
  if (currentEnvironment !== ENVIRONMENTS.PROD) {
    console.log('-->', getTimestamp(), ...args);
  }
}

export function debugError(...args: any[]) {
  if (currentEnvironment !== ENVIRONMENTS.PROD) {
    console.error('-->', getTimestamp(), ...args);
  }
}

export function checkCardBadge(props: any) {
  if (props.hasOwnProperty('badge') && props.badge) {
    if (props.badge.hasOwnProperty('icon') && props.badge.icon) {
      const C = getIcon(props.badge.icon);
      props.badge.iconChild = <C />;
    }
    if (props.badge.hasOwnProperty('hideLabel') && props.badge.hideLabel) {
      delete props.badge.hideLabel;
      delete props.badge.label;
    }
    if (props.badge.hasOwnProperty('skin') && props.badge.skin) {
      const badgeStatuses = [
        'general',
        'neutral',
        'promotional',
        'success',
        'error',
        'warning',
      ];
      if (badgeStatuses.includes(props.badge.skin.toLowerCase())) {
        props.badge.status = props.badge.skin;
      } else {
        props.badge.status = 'general';
      }
      delete props.badge.skin;
    }
  }
}

export const scrollToSection = (id: string) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      block: 'start',
      behavior: 'smooth',
    });
    window.location.hash = `#${id}`;
  }
};

export function getContentPath(route: string, locale: string) {
  let pageRegion = locale.split('-')[1].toLowerCase(); // default to gb
  const pageLanguage = locale.split('-')[0].toLowerCase(); // default to en
  const basePaths = getAEMBasepath();
  const basePath = isPreviewMode ? basePaths.preview : basePaths.main;
  const isLocal = currentEnvironment === ENVIRONMENTS.LOCAL;
  const brandPath = currentBrand === 'VAA' ? 'vaa-home' : 'vhols';

  let path = '';

  if (route === '/') {
    if (isTravelPlusHomepage) {
      if (pageRegion.toLowerCase() === 'us') {
        pageRegion = 'gb';
      }
      path = `${pageRegion}/travelplus.model.json`;
    } else {
      if (isLocal) {
        path = `content/${brandPath}/${pageRegion}/${pageLanguage}.model.json`;
      } else {
        path = `${pageRegion}/${pageLanguage}.model.json`;
      }
    }
  } else {
    if (isPreviewMode) {
      path = route;
    } else {
      path = `${pageRegion}/${pageLanguage}${route}.model.json`;
    }
  }

  const backendUrl = (basePath + path).replace(/([^:]\/)\/+/g, '$1');
  return backendUrl;
}

export function hardRedirectTo(url: string) {
  if (typeof window !== 'undefined') {
    // TODO: Check if the url is good
    window.location.assign(url);
  }
}

export function checkLocaleData(locale: string): string | null {
  if (locale.includes('-') && locale.length === 5) {
    const [language, region] = locale.split('-');
    return [language.toLowerCase(), region.toUpperCase()].join('-');
  }
  return null;
}
