import styles from './search-panel.component.module.less';
import classnames from 'classnames';
import HTMLRenderer from '../html-renderer/html-renderer.component';
import { useState } from 'react';
import {
  ButtonComponent,
  ButtonColour,
  ButtonSize,
  ButtonType,
  IconPosition,
} from '@vaa-component-lib/component.atom.button/';
import {
  IconActionCrossComponent,
  IconActionSearchComponent,
} from '@vaa-component-lib/component.atom.icons';

interface SearchPanelComponentProps {
  componentId?: string;
  ESIString: string;
  displayButton: boolean;
  dataCy?: string;
  isVisible?: boolean;
}

const SearchPanelComponent = ({
  componentId,
  ESIString,
  displayButton,
  dataCy = 'search-panel',
  isVisible = true,
}: SearchPanelComponentProps) => {
  const [showSearch, setShowSearch] = useState(false);
  const handleClick = () => setShowSearch(!showSearch);

  if (!isVisible || !ESIString || ESIString.includes('PLACEHOLDER')) {
    return null;
  }

  return (
    <div
      {...(componentId && { id: componentId })}
      data-cy={dataCy}
      className={classnames(styles['search-toggle'])}
    >
      {displayButton && (
        <div
          className={classnames(styles.toggler, {
            [styles['toggled']]: showSearch,
          })}
        >
          <ButtonComponent
            fullWidth={true}
            iconChild={
              showSearch ? (
                <IconActionCrossComponent />
              ) : (
                <IconActionSearchComponent />
              )
            }
            colour={showSearch ? ButtonColour.Contrast : ButtonColour.Brand}
            size={ButtonSize.Small}
            text={showSearch ? 'Close Search' : 'Find Holidays'}
            type={ButtonType.Button}
            onClick={handleClick}
            iconPosition={IconPosition.Right}
          />
        </div>
      )}
      <div className={classnames(styles.togglee)}>
        <HTMLRenderer htmlString={ESIString} />
      </div>
    </div>
  );
};

export default SearchPanelComponent;
