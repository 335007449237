import { getEndpoint } from '../basepaths';
import { getHeaders, handleResponse } from '../api-utils/utils';
import { JSONObject } from '../api-utils/types';

const getAllHotels = async (): Promise<JSONObject> => {
  const requestOptions = {
    method: 'GET',
    headers: getHeaders(),
  };
  const response = await fetch(
    `${getEndpoint().FEDERATED_DATA_API.ABS}/browse/hotels`,
    requestOptions
  );
  return handleResponse(response);
};

const getHotel = async (hotelUrlName: string): Promise<JSONObject> => {
  const requestOptions = {
    method: 'GET',
    headers: getHeaders(),
  };
  const response = await fetch(
    `${getEndpoint().FEDERATED_DATA_API.ABS}/browse/${hotelUrlName}/name`,
    requestOptions
  );
  return handleResponse(response);
};

const getHolidaysByLocation = async (
  location: string,
  isClientSide: boolean = false
): Promise<JSONObject> => {
  const requestOptions = {
    method: 'GET',
    headers: getHeaders(),
  };
  if (isClientSide) {
    const origin =
      isClientSide && window.location ? window.location.origin : '';
    const response = await fetch(
      `${getEndpoint(origin).FEDERATED_DATA_API.REL}/browse/${location}/hotels`,
      requestOptions
    );
    const hotelsResponse = await handleResponse(response);
    return hotelsResponse;
  } else {
    const response = await fetch(
      `${getEndpoint().FEDERATED_DATA_API.ABS}/browse/${location}/hotels`,
      requestOptions
    );
    const hotelsResponse = await handleResponse(response);
    return hotelsResponse;
  }
};

export const holidaysService = {
  getAllHotels,
  getHotel,
  getHolidaysByLocation,
};
